/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Sat Nov 09 2024
 */

import type {
  InjectionKey,
  Ref,
} from 'vue';

export const SCROLL_DIV_REF: InjectionKey<Ref<HTMLDivElement | undefined>> = Symbol(
  'Scroll Div Ref for scroll to top button',
);
