<!--
 - Ankur Mursalin
 - encryptioner.github.io
 - December 08, 2021
 -->

<template>
  <div class="relative min-h-screen flex-col font-balooDa2">
    <main>
      <BusNotification />
      <div>
        <CustomLoader1
          v-if="appStore.isLoading"
          class="modal modal-open"
        />
        <ScrollToTopButton />
        <RouterView />
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>

import {
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from 'vue';
import {
  viewportBreakpoint,
} from './helpers';
import BusNotification from '@/components/App/BusNotification.vue';
import ScrollToTopButton from '@/components/Helper/Button/ScrollToTopButton.vue';
import CustomLoader1 from '@/components/Helper/Loader/CustomLoader1.vue';
import {
  useStore,
} from '@/store';

let storageEventHandler: (e: StorageEvent) => void;
let resizedBreakpointEventHandler: (e: Event) => void;

const appStore = useStore.app;
const authStore = useStore.auth;
const backendStore = useStore.backend;

watch(() => authStore.token, (newVal, oldVal) => {
  const triggerAuthChangeHandler = (newVal && !oldVal) || (oldVal && !newVal);
  if (triggerAuthChangeHandler) {
    backendStore.initOnAuthChange();
  }
});

onBeforeMount(() => {
  storageEventHandler = (e: StorageEvent): void => {
    if (e.key === 'token') {
      // local storage token changed
      console.log(`Key Changed: ${e.key}`);
      window.sessionStorage.removeItem('token');
      window.location.reload();
    }
  };
  resizedBreakpointEventHandler = (e: Event): void => {
    const resizedBreakpoint = viewportBreakpoint();
    if (resizedBreakpoint !== appStore.breakpoint) {
      appStore.breakpoint = resizedBreakpoint;
    }
  };
  window.addEventListener('resize', resizedBreakpointEventHandler);
  window.addEventListener('storage', storageEventHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('storage', storageEventHandler);
  window.removeEventListener('resize', resizedBreakpointEventHandler);
});

</script>
